<template>
  <div>
    <div class="verify">
      <div class="logo"></div>
      <div class="form">
        <form v-if="!loading && set_password" @submit.prevent>
          <h5 class="text-center">Reset password</h5>
          <input
            type="password"
            required
            placeholder="New Password"
            v-model="user.password"
          />
          <input
            required
            type="password"
            placeholder="Confirm Password"
            v-model="user.confirmPassword"
          />

          <button
            :disabled="loading_password"
            class="btn auth-btn"
            type="submit"
            @click="onReset"
          >
            <span
              v-show="loading_password"
              class="spinner-border spinner-border-sm"
            ></span>
            <span v-show="!loading_password">Submit</span>
          </button>
        </form>
        <b-spinner v-show="loading" variant="primary"></b-spinner>

        <p v-show="error" class="error">{{ error }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import ikebana from "../../services/ikebana";
export default {
  props: {
    id: String,
    code: String,
  },
  data() {
    return {
      loading: false,
      loading_password: false,
      set_password: true,
      error: "",
      user: {
        id: "",
        code: "",
        password: "",
        confirmPassword: "",
      },
    };
  },
  methods: {
    onReset() {
      this.loading_password = true;
      ikebana.reset(this.user).then(
        (response) => {
          this.$store.dispatch("SET_USER", response.data);
          this.loading_password = false;
          this.$store.dispatch("LOAD_DATA");
          this.$router.push("/order/");
        },
        (error) => {
          this.error = error.response.data.Message;
          this.loading_password = false;
        }
      );
    },
  },
  created: function () {
    var params = this.$route.query;
    this.user.id = params.id;
    this.user.code = params.code;
  },
};
</script>
<style lang="css">
.verify {
  background: white;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  align-content: center;
  justify-content: center;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 20px 50px 20px 50px;
}

.error-container {
  height: 100px;
  min-height: 100px;
}
.error {
  color: red;
  text-align: center;
  margin-top: 50px;
}
@media (max-width: 768px) {
  .forgot {
    width: 100% !important;
    height: 60vh !important;
  }
}
.auth-btn {
  border-radius: 12px;
  background: #ad1f23;
}
a {
  text-decoration: none;
  text-align: center;
  color: #707070;
}

.verify input {
  margin-bottom: 10px;
}

input {
  width: 100%;
  height: 50px;
}

.logo {
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  background: url("../../assets/logo.png") center center no-repeat;
}
</style>
